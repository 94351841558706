 :root {
    --peppermint: #13C87B;
    --peppermint-50: rgba(19, 200, 123, 0.5);
    --sunrise: #FFC754;
    --dark-grey: #363636;
    --blue: #003492;
    --blue-50: rgba(0, 52, 146, 0.5);
    --blue-25: rgba(0, 52, 146, 0.25);
    --bluesteel: #90a8c0;
    --bluesteel-10: rgba(144, 168, 192, 0.1);
    --skin: #D7B58A;
    --dusk-pink: #EBC0B8;
    --teal: #4EC7C6;
    --white-50: rgba(255, 255, 255, 0.5);
}

html, body {
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    color: var(--dark-grey);
    height: 100%;
    position: relative;
}

header,
footer {
    position: fixed;
    height: 64px;
    background-color: var(--dark-grey);
    color: white;
    left: 0;
    right: 0;
    z-index: 50;
}

footer {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;

    section {
        padding: 0 16px;
    }

    a {
        color: white;

        &:hover {
            opacity: 0.8;
        }
    }
}

header {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;

    .brand {
        position: absolute;
        left: 1rem;
    }

    img{ 
        &.normal {
            display: block;
        }
        &.small {
            display: none;
        }
    }

    h2 {
        color: white;
        font-weight: 900;
        margin: 0 16px;
    }
}

@media screen and (max-width: 768px) {
    header {
        justify-content: flex-end;
    }
}

@media screen and (max-width: 480px) {
    header img { 
        &.normal {
            display: none;
        }
        &.small {
            display: block;
        }
    }

    footer {
        justify-content: start;
        
        section {
            display: flex;
            flex-direction: column;
            
            span.v-bar {
                display: none;
            }
        }
    }
}

main {
    position: absolute;
    top: 64px;
    left: 0;
    right: 0;
    bottom: 0;
}

h2, h3, h4 {
    font-family: 'Lato', sans-serif;
}

h2, h3 {
    text-align: center;
}

h2 {
    color: var(--peppermint);
}

h3 {
    color: var(--peppermint);
    small {
        font-weight: 500;
        color: var(--bluesteel)
    }
}

label small {
    color: var(--bluesteel);
}

aside p {
    font-size: 1rem;
    line-height: 1.5rem;
}

.step-controls {
    min-height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

button {
    border: none;
    color: black;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 4px;
    background-color: white;
    border: solid 1px var(--peppermint);
    padding: 8px 16px;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
        background-color: var(--peppermint-50);
    }

    &.cta {
        background-color: var(--peppermint);
        color: white;
    }

    &.inactive {
        background-color: var(--bluesteel);
        pointer-events: none;
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(47, 68, 106, 0.4);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(47, 68, 106, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(47, 68, 106, 0);
    }
}

